import React from 'react';

import { Link } from 'components/elements/Links/Link';

import styles from './styles.module.css';

export const ScreenSuccess = () => (
  <div className="section">
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h1>Thank you for getting in touch!</h1>
        <p>
          One of our colleagues will get back in touch with you soon.
          <br />
          Have a great day!
        </p>
        <Link to="/" variant="lg">
          Back to the home page
        </Link>
      </div>
    </div>
  </div>
);
