import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { string, bool, node } from 'prop-types';

import { isInternalLink } from 'base/utils/isInternalLink';
import { isWebLink } from 'base/utils/isWebLink';

import styles from './styles.module.css';

export const Link = ({
  to = '/',
  theme = 'primary',
  variant,
  disabled,
  role,
  children,
  ...rest
}) => {
  const styling = { className: styles.link, theme, variant, disabled };

  if (role === 'button') {
    return (
      <button type="button" {...styling} {...rest}>
        {children}
      </button>
    );
  }

  const isInternal = isInternalLink(to);

  if (isInternal && to !== '/') {
    return (
      <GatsbyLink
        {...styling}
        to={to}
        activeClassName={styles.active}
        {...rest}
      >
        {children}
      </GatsbyLink>
    );
  }

  const target = isWebLink(to) ? '_blank' : '_self';

  return (
    <a
      {...styling}
      href={to}
      target={target}
      rel="noopener noreferrer"
      {...rest}
    >
      {children}
    </a>
  );
};

Link.propTypes = {
  to: string,
  theme: string,
  variant: string,
  role: string,
  disabled: bool,
  children: node,
};
